import axios from "axios";

export const authentication =(id) =>{
  let data = {id:id};
  let url = "https://oscontrato.azurewebsites.net/api/v1.0/RequestTokenGetNet";
  return new Promise((resolve, reject) => {
    axios
      .post(url,data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
    });
}

export const findAddress = (cep) => {
  let url = `https:viacep.com.br/ws/${cep}/json/`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
