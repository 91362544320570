import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import "./App.css";


import Payment from "./pages/payment/Payment";
import NotFound from "./pages/notFound/NotFound";

function Child() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('key');
  return id ? <Payment id={id}/> : <NotFound/>;
}

export default function App() {
  return (
    <Router>
      <Switch>
          <Route path="/" children={<Child/>} />
        </Switch>
    </Router>
  );
}