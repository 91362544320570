import React, { useEffect, useState, useCallback, useMemo } from "react";
import ScriptTag from "react-script-tag";
import { authentication, findAddress } from "./PaymentService";
import {
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  Card,
  Row,
  Col,
  Spinner
} from "reactstrap";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-input";
import { cpf } from 'cpf-cnpj-validator';
import { FiSquare, FiCheckSquare, FiRefreshCcw } from "react-icons/fi";

import logoGetnet from '../../assets/novologotipogetnet.png'
import credit from '../../assets/credit.png';
import debit from '../../assets/debit.png';

function Payment({ id }) {
  const [loading, setLoading] = useState(true);
  const [agreement, setAgreement] = useState(false);
  const [selected, setSelected] = useState(null);
  const [blockPaymentModal, setBlockPayment] = useState(false);
  const [showPaymentModal, setShowPayment] = useState(false);
  const [maskCep, setMaskCep] = useState("");
  const [maskAmount, setMaskAmount] = useState(null);
  const [invalidInput, setInvalidInput] = useState({
    amount: false,
    firstName: false,
    lastName: false,
    documentNumber: false,
    email: false,
    phoneNumber: false,
    streetNumber: false,
    complementary: false,
    zipcode: false,
    agree: false
  });
  const [payment, setPayment] = useState({
    sellerId: "",
    token: "",
    urlLogo: "",
    nomeEmpresa: "",
    orderId: "12345",
    customer: {
      id: "12345",
      firstName: "",
      lastName: "",
      documentType: "CPF",
      documentNumber: "",
      email: "",
      phoneNumber: "",
    },
    address: {
      street: "Rua Manuel de Arzão",
      streetNumber: "85",
      complementary: "",
      neighborhood: "Vila Albertina",
      city: "São Paulo",
      state: "SP",
      zipcode: "02730030",
      country: "Brasil",
    },
    items:
      '[{"name": "","description": "", "value": 0, "quantity": 0,"sku": ""}]',
  });

  useEffect(() => {
    console.log("id", id)
    let aux1 = sessionStorage.getItem(btoa('customer'));
    let aux2 = aux1 ? atob(aux1) : null;
    let customer = aux2 ? JSON.parse(aux2) : payment.customer;
    let amount = atob(sessionStorage.getItem(btoa('amount')));
    authentication(id)
      .then(res => {
        const token = `${res.token_type} ${res.access_token}`;
        setPayment(p => ({
          ...p,
          token: token,
          sellerId: res.sellerId,
          urlLogo: res.urlLogo,
          nomeEmpresa: res.nomeEmpresa,
          customer
        }));
        amount && setMaskAmount(amount);
      })
      .catch(err => window.location.search = "")
      .finally(() => setLoading(false));
  }, [id]);

  const responseListener = () => {
    var element = document.querySelector('#getnet-checkout');
    if (element)
      element.addEventListener('load', ev => {
        // Funções compatíveis com IE e outros navegadores
        var eventMethod = (window.addEventListener ? 'addEventListener' : 'attachEvent');
        var eventer = window[eventMethod];
        var messageEvent = (eventMethod === 'attachEvent') ? 'onmessage' : 'message';

        // Ouvindo o evento do loader
        eventer(messageEvent, function iframeMessage(e) {
          var data = e.data || '';

          switch (data.status || data) {
            // Corfirmação positiva do checkout.
            case 'success':
              authentication(id)
                .then(res => console.log("deu bom bom", res))
                .catch(err => console.warn("deu bom ruim", err))
                .finally(() => setLoading(false));

              console.log('Transação realizada.', e, data);
              break;

            // Notificação de que o IFrame de checkout foi fechado a aprovação.
            case 'close':
              console.log('Checkout fechado.', e, data);
              break;

            // Notificação que um boleto foi registrado com sucesso 
            case 'pending':
              console.log('Boleto registrado e pendente de pagamento', e, data);
              console.log(data.detail);
              break;

            // Notificação que houve um erro na tentativa de pagamento 
            case 'error':
              authentication(id)
                .then(res => console.log("deu bom bom", res))
                .catch(err => console.warn("deu bom ruim", err))
                .finally(() => setLoading(false));

              console.warn(data.detail.message, e, data);
              break;

            // Ignora qualquer outra mensagem 
            default:
              break;
          }
        }, false);
      });
  }

  const removeSpecialChar = (data = "") => {
    return data
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("")
      .split("-")
      .join("")
      .split(".")
      .join("")
      .split(",")
      .join("");
  };

  const formatMoney = (data) => {
    return data
      .split("R$ ")
      .join("")
      .split(".")
      .join("")
      .split(",")
      .join(".");
  }

  const handleSearchCep = (cep) => {
    setMaskCep(cep);//armazena cep mascarado
    if (removeSpecialChar(cep).length === 8) { mountAdress(cep) };
  }

  const mountAdress = (cep = maskCep) => {
    findAddress(removeSpecialChar(cep))
      .then((data) => {
        if (data.erro) {
          throw new Error("CEP não encontrado");
        }
        else {
          let obj = {
            street: data.logradouro,
            complementary: data.complemento,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            zipcode: data.cep,
          };
          setPayment({ ...payment, address: { ...payment.address, ...obj } });
          setInvalidInput({ ...invalidInput, zipcode: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setPayment({
          ...payment,
          address: {
            street: "",
            streetNumber: "",
            complementary: "",
            neighborhood: "",
            city: "",
            state: "",
            zipcode: "",
            country: "Brasil",
          }
        });
        setInvalidInput({ ...invalidInput, zipcode: true });
      });
    showPaymentModal && setShowPayment(false);
  };

  const validateInputs = async (inputs) => {
    try {
      const schema = Yup.object().shape({
        id: Yup.string(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        documentType: Yup.string(),
        documentNumber: Yup.string().min(11).required(),
        email: Yup.string().email().required(),
        phoneNumber: Yup.string().min(10).required(),
        // streetNumber: Yup.string().required(),
        // complementary: Yup.string(),
        // zipcode: Yup.string().required(),
      });

      let data = payment.customer;
      // const {
      //   streetNumber,
      //   complementary,
      //   zipcode,
      // } = payment.address;
      data.documentNumber = removeSpecialChar(data.documentNumber);
      data.phoneNumber = removeSpecialChar(data.phoneNumber);
      // data.streetNumber = streetNumber;
      // data.complementary = complementary;
      // data.zipcode = zipcode;

      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed

    } catch (err) {

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          inputs[error.path] = true;
        });
      }

    } //validacao basica dos campos

    if (!maskAmount || maskAmount === "R$ 0,00") {
      inputs.amount = true;
    }//valida montante

    inputs.agree = !agreement;//valida concentimento

    const isValidCpf = cpf.isValid(removeSpecialChar(payment?.customer?.documentNumber));
    inputs.documentNumber = !isValidCpf;//valida cpf

    setInvalidInput({ ...inputs });
    return inputs;
  };

  const handleAmount = useCallback((data, index = -1) => {
    setMaskAmount(data);
    setInvalidInput(i => ({ ...i, amount: false }));
    setSelected(index);
    setShowPayment(false);
  }, []);

  const handleChange = (name, value) => {
    let change = {};
    change[name] = value;
    setPayment({ ...payment, customer: { ...payment.customer, ...change } });

    //set input value as valid
    validateInputValue(name);

    showPaymentModal && setShowPayment(false);
  };

  const handleChangeAdress = (name, value) => {
    let change = {};
    change[name] = value;
    setPayment({ ...payment, address: { ...payment.address, ...change } })

    //set input value as valid
    validateInputValue(name);
    showPaymentModal && setShowPayment(false);
  };

  const validateInputValue = (name) => {
    let invalid = {};
    invalid[name] = false;
    setInvalidInput({ ...invalidInput, ...invalid });
  }

  const handleSubmit = async () => {
    let inputs = invalidInput;
    const newInputsValidated = await validateInputs(inputs);//valida inputs retorna obj

    const arrayValidationValue = Object.values(newInputsValidated);//filtra somente valores boolean

    if (!arrayValidationValue.some((item) => item === true)) {
      setShowPayment(true); //se nenhum input for inválido chama modal 
    };
  };

  const creatGetnetPaymentModal = useMemo(() => {
    return showPaymentModal ? (
      <ScriptTag
        async src="https://checkout.getnet.com.br/loader.js"//"https://checkout-homologacao.getnet.com.br/loader.js"//
        data-getnet-sellerid={payment.sellerId}
        data-getnet-token={payment.token}
        data-getnet-amount={formatMoney(maskAmount)}
        data-getnet-customerid={payment.customer.id}
        data-getnet-orderid={payment.orderId}
        data-getnet-installments="1"
        data-getnet-button-class="open-checkout"
        data-getnet-customer-first-name={payment.customer.firstName}
        data-getnet-customer-last-name={payment.customer.lastName}
        data-getnet-customer-document-type={payment.customer.documentType}
        data-getnet-customer-document-number={removeSpecialChar(
          payment.customer.documentNumber
        )}
        data-getnet-customer-email={payment.customer.email}
        data-getnet-customer-phone-number={removeSpecialChar(
          payment.customer.phoneNumber
        )}
        data-getnet-customer-address-street={payment.address.street}
        data-getnet-customer-address-street-number={payment.address.number}
        data-getnet-customer-address-complementary={
          payment.address.complementary
        }
        data-getnet-customer-address-neighborhood={payment.address.neighborhood}
        data-getnet-customer-address-city={payment.address.city}
        data-getnet-customer-address-state={payment.address.state}
        data-getnet-customer-address-zipcode={removeSpecialChar(
          payment.address.zipcode
        )}
        data-getnet-customer-country="BR"
        data-getnet-shipping-address='[{ "first_name": "Marco", "name": "Marco Jose Franceschini", "email": "ingrid-tf@hotmail.com", "phone_number": "1730411321", "shipping_amount": 0.00, "address": { "street": "Morro de São Paulo", "complement": "", "number": "1", "district": "Morro de São Paulo", "city": "Cairu", "state": "SP", "country": "BR", "postal_code": "45420000"}}]'
        data-getnet-items='[{"name": "Produto","description": "Tamanho M", "value": 0, "quantity": 0,"sku": "324"}]'
        data-getnet-url-callback=""
        data-getnet-gn3ds="true"
        data-getnet-gn3ds-currency="BRL"
        data-getnet-gn3ds-merchant-backend-url="https://api.getnet.com.br/v2/hubbff/3ds/"//"https://api-homologacao.getnet.com.br/v2/hubbff/3ds/"//
        data-getnet-gn3ds-token-type="oauth"
        data-getnet-gn3ds-merchant-backend-token-oauth={payment.token}
        data-getnet-gn3ds-environment="PRD"//"SDB"//
        data-getnet-gn3ds-framework-modal="bootstrap3"
        data-getnet-gn3ds-shipping-method="lowcost"
        data-getnet-gn3ds-shipping-destination-code="01"
      />
    ) : null;
  }, [showPaymentModal]);

  const createDoneteBoxes = useCallback(() => {
    const donateValues = [
      "R$ 10,00",
      "R$ 15,00",
      "R$ 20,00",
      "R$ 30,00",
      "R$ 50,00"
    ];
    return donateValues.map((item, index) => {
      return (
        <div
          key={index}
          style={{ cursor: "pointer" }}
          className={selected === index ? "donateBoxSelected" : "donateBox"}
          onClick={() => {
            handleAmount(item, index);
          }}
        >
          <span
            className={
              selected === index ? "donateTextSelected" : "donateText"
            }
          >R$</span>
          <span
            className={
              selected === index ? "donateTextSelected bigText" : "donateText bigText"
            }
          >
            {item.substr(3, 2)}
          </span>
        </div>
      );
    });
  }, [selected, handleAmount]);

  return (
    <div className="appContainer">
      {
        (payment.urlLogo || payment.nomeEmpresa) ?
          <Card className="mainCard">
            <h4 className="themeText" style={{ alignSelf: 'center' }}>{payment.nomeEmpresa}</h4>
            <img className="logo" src={payment.urlLogo} alt="logoEmpresa" />
          </Card>
          : null
      }

      {!loading ? (<>
        <Card className="mainCard">
          <Form className="mainForm" onSubmit={(e) => e.preventDefault()}>
            <h3>Valores doação</h3>
            <Row className="rowAgroup">{createDoneteBoxes()}</Row>
            <FormGroup>
              <Label for="amount">Outro valor</Label>
              <CurrencyInput
                id="amount"
                name="amount"
                placeholder={`R$`}
                className={"form-control"}
                precision="2"
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                maxLength={14}
                value={maskAmount}
                onChangeEvent={(e) => handleAmount(e.target.value)}
              />
              {invalidInput.amount && (
                <FormText className="inputError">
                  Valor não pode ser zero !
                </FormText>
              )}
            </FormGroup>
            <h3>Informe seus dados</h3>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="firstName">Nome*</Label>
                  <Input
                    type="text"
                    maxLength={50}
                    name="firstName"
                    id="firstName"
                    placeholder="Digite o nome"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={payment?.customer?.firstName}
                  />
                </FormGroup>
                {invalidInput.firstName && (
                  <FormText className="inputError">
                    Nome é obrigatório !
                  </FormText>
                )}
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="lastName">Sobrenome*</Label>
                  <Input
                    type="text"
                    maxLength={50}
                    name="lastName"
                    id="lastName"
                    placeholder="Digite o sobrenome"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={payment?.customer?.lastName}
                  />
                  {invalidInput.lastName && (
                    <FormText className="inputError">
                      Sobrenome é obrigatório !
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="documentNumber">CPF*</Label>
                  <NumberFormat
                    type="text"
                    id="documentNumber"
                    name="documentNumber"
                    placeholder="Digite o CPF"
                    className={`form-control number-format`}
                    format="###.###.###-##"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={payment?.customer?.documentNumber}
                  />
                  {invalidInput.documentNumber && (
                    <FormText className="inputError">
                      CPF é obrigatório !
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="phoneNumber">Telefone*</Label>
                  <NumberFormat
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Digite o telefone"
                    className={`form-control number-format`}
                    format="(##) #####-####"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    value={payment?.customer?.phoneNumber}
                  />
                  {invalidInput.phoneNumber && (
                    <FormText className="inputError">
                      Telefone é obrigatório !
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="email">Email*</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Digite o email"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={payment?.customer?.email}
              />
              {invalidInput.email && (
                <FormText className="inputError">Email é obrigatório !</FormText>
              )}
            </FormGroup>
            {/* <h3>Endereço</h3>
          <Row>
            <Col sm={4}>
              <FormGroup>
                <Label for="zipcode">CEP*</Label>
                <NumberFormat
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  placeholder="Digite o CEP"
                  className={`form-control number-format`}
                  format="##.###-###"
                  onBlur={() => mountAdress()}
                  onChange={(e) => handleSearchCep(e.target.value)}
                />
                {invalidInput.zipcode && (
                  <FormText className="inputError">
                    CEP não encontrado !
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm={8}>
              <FormGroup>
                <Label for="street">Endereço*</Label>
                <Input
                  disabled
                  type="text"
                  name="street"
                  id="street"
                  value={payment.address.street}
                />
                {invalidInput.street && (
                  <FormText className="inputError">
                    Endereço é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label for="streetNumber">Número*</Label>
                <Input
                  type="number"
                  maxLength={1}
                  name="streetNumber"
                  id="streetNumber"
                  placeholder="Digite o número da residência"
                  onChange={(e) =>handleChangeAdress(e.target.name,e.target.value)}
                />
                {invalidInput.streetNumber && (
                  <FormText className="inputError">
                    Número da residência é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label for="complementary">Complemento</Label>
                <Input
                  type="text"
                  maxLength={50}
                  name="complementary"
                  id="complementary"
                  placeholder="Digite o complemento (opcional)"
                  onChange={(e) =>handleChangeAdress(e.target.name,e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <FormGroup>
                <Label for="neighborhood">Bairro*</Label>
                <Input
                  disabled
                  type="text"
                  name="neighborhood"
                  id="neighborhood"
                  value={payment.address.neighborhood}
                />
                {invalidInput.neighborhood && (
                  <FormText className="inputError">
                    Bairro é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <Label for="city">Cidade*</Label>
                <Input
                  disabled
                  type="text"
                  name="city"
                  id="city"
                  value={payment.address.city}
                />
                {invalidInput.city && (
                  <FormText className="inputError">
                    Cidade é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Label for="state">UF*</Label>
                <Input
                  disabled
                  type="text"
                  name="state"
                  id="state"
                  value={payment.address.state}
                />
                {invalidInput.state && (
                  <FormText className="inputError">
                    UF é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row> */}
            <Card className={`secondaryCard ${agreement && "bordered"}`} onClick={() => setAgreement(!agreement)}>
              <FormGroup>
                <Label for="agree" className="themeText">Termo de consentimento*</Label>
                {agreement ?
                  <FiCheckSquare
                    name="agree"
                    id="agree"
                    style={{ marginLeft: 10, width: 18, height: 18, color: "red" }}
                  />
                  :
                  <FiSquare
                    name="agree"
                    id="agree"
                    style={{ marginLeft: 10, width: 20, height: 20 }}
                  />
                }
                <FormText>
                  Estou ciente e confirmo a coleta de dados pessoas para realização do pagamento
                </FormText>
                {invalidInput.agree && (
                  <FormText className="inputError">
                    Termo de consentimento é obrigatório !
                  </FormText>
                )}
              </FormGroup>
            </Card>
            {!showPaymentModal ? (
              <div className="btnConfirm btn" onClick={handleSubmit}>
                Confirmar Dados
              </div>
            ) : (
              <Button
                color="warning"
                onClick={() => {
                  sessionStorage.setItem(btoa("customer"), btoa(JSON.stringify(payment.customer)));
                  sessionStorage.setItem(btoa("amount"), btoa(maskAmount));
                  window.location.reload();
                }}
              >
                <FiRefreshCcw />
                Tentar novamente
              </Button>
            )}
          </Form>
          {showPaymentModal ? (
            <Button
              color="danger"
              className="open-checkout"
              disabled={blockPaymentModal}
              onClick={() => { !blockPaymentModal && setBlockPayment(true); responseListener(); }}>
              Pagar via checkout
              <img src={logoGetnet} style={{ height: 20 }} alt="logo getnet" />
            </Button>
          ) : null}
          <div className="flagCardContainer">
            <div className="col">
              <spam className="themeText">Bandeiras Crédito</spam>
              <img src={credit} className="flagCard" alt="bandeiras crédito" />
            </div>
            <div className="col">
              <spam className="themeText">Bandeiras Débito</spam>
              <img src={debit} className="flagCard" alt="bandeiras débito" />
            </div>
          </div>
        </Card>
        {creatGetnetPaymentModal}
      </>
      ) : (
        <>
          <Spinner type="grow" color="danger" style={{ width: '10rem', height: '10rem' }} />
        </>
      )}
    </div>
  );
}

export default Payment;
